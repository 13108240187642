import React, { useEffect, useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"

export function Filter(props) {
  const [active, setActive] = useState(false);
  let bgcolor = active ? "#CCCCCC" : "";
  return (
    <Chip //avatar={<Avatar>M</Avatar>}
          label={props.tag}
          variant={active ? "outlined" : ""}
          style={{ background: bgcolor }}
          onClick={() => {
            setActive(!active);
            if (!active) {
              props.setActiveTags(props.activeTags.concat([props.tag]))
            } else {
              props.setActiveTags([...props.activeTags].filter(x => x !== props.tag));
            }
            console.log(props.activeTags)
          }
          } />);
}